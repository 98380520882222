<template>
  <div class="flex flex-col cursor-pointer relative" @click="to(link)">
    <el-image
      :src="$filters.uploadUrl(cover, 'md')"
      class="aspect-w-16 aspect-h-10"
      lazy
      fit="cover"
    />
    <div class="mt-4">
      <h4 class="font-bold text-lg" v-highlight="keyword">
        {{ title }}
      </h4>
      <p class="text-sm">登錄號 : {{ serial }}</p>
    </div>
  </div>
</template>

<script>
import { ElImage } from 'element-plus';
export default {
  components: { ElImage },
  props: {
    keyword: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    cover: {
      type: String,
    },
    serial: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  setup() {
    const to = (link) => {
      window.location.href = link;
    };
    return { to };
  },
};
</script>
