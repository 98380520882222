<template>
  <App-necker :title="'藏品列表'" />
  <div class="container">
    <List :service="WorkService" :fields="fields">
      <template v-slot:item="slot">
        <Card
          :keyword="slot.keyword"
          :link="`/work/${slot.row.id}/${slot.row.slug}`"
          :title="slot.row.title"
          :cover="slot.row.cover"
          :serial="slot.row.serial"
        />
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/pages/index/components/list/Index.vue';
import WorkService from '@/services/Work';
import Card from '@/pages/index/components/list/CardWork.vue';
import { random } from 'lodash';

export default {
  components: {
    List,
    Card,
  },
  setup() {
    return {
      random,
      WorkService,
      fields: {
        creator: null,
        keyword: null,
        year: null,
        serial: null,
        category: null,
      },
    };
  },
};
</script>
